import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

let IndexPage = () => {

    return (
        <>
            < Layout >
                <SEO title="Politique de confidentialité" />

                <article id="main" className="services">
                    <header>
                        <h2>Privacy Policy</h2>
                        <p></p>
                    </header>
                    <section className="wrapper style5">
                        <h1>Politique de Confidentialité</h1>

                        <p>Date de prise d'effet: August 09, 2019</p>

                        <p>
                            Brico Barrière ("nous", "notre", "nos") exploite le site web https://bricobarriere.be
                            (ci-après désigné par le terme "Service").
  </p>

                        <p>
                            Cette page vous explique nos politiques en matière de collecte,
                            d'utilisation et de communication des données à caractère personnel
                            lorsque vous utilisez notre Service ainsi que les choix qui
                            s'offrent à vous en ce qui concerne ces données. Our Privacy Policy
    for Brico Barrière is created with the help of the{' '}
                            <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator.php">
                                Free Privacy Policy Generator
    </a>
    .
  </p>

                        <p>
                            Nous utilisons vos données pour fournir et améliorer le Service. En
                            utilisant le Service, vous consentez à la collecte et à
                            l'utilisation d'informations conformément à la présente politique.
                            Sauf définition contraire dans la présente Politique de
                            Confidentialité, les termes utilisés dans la présente Politique de
                            Confidentialité ont la même signification que dans nos Conditions
                            Générales qui sont disponibles sur https://bricobarriere.be
  </p>

                        <h2>Définitions</h2>
                        <ul>
                            <li>
                                <p>
                                    <strong>Service</strong>
                                </p>
                                <p>
                                    Par Service on entend le site web https://bricobarriere.be exploité par
                                    Brico Barrière
      </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Données à caractère personnel</strong>
                                </p>
                                <p>
                                    Données à Caractère Personnel désigne des données concernant un
                                    individu vivant qui peut être identifié à partir de ces données
                                    (ou à partir de ces données et d'autres informations en notre
                                    possession ou susceptibles d'entrer en notre possession).
      </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Données d'Utilisation</strong>
                                </p>
                                <p>
                                    Les Données d'Utilisation sont recueillies automatiquement et
                                    sont générées soit par l'utilisation du Service, soit par
                                    l'infrastructure du Service proprement dite (par exemple, durée
                                    de consultation d'une page).
      </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Cookies</strong>
                                </p>
                                <p>
                                    Les cookies sont de petits fichiers enregistrés sur votre
                                    dispositif (ordinateur ou dispositif mobile).
      </p>
                            </li>
                        </ul>

                        <h2>Collecte et utilisation des données</h2>
                        <p>
                            Nous recueillons plusieurs types de données à différentes fins en
                            vue de vous fournir notre Service et de l'améliorer.
  </p>

                        <h3>Types de données recueillies</h3>

                        <h4>Données à Caractère Personnel</h4>
                        <p>
                            Lorsque vous utilisez notre Service, il est possible que nous vous
                            demandions de nous fournir certaines données personnelles
                            nominatives qui peuvent servir à vous contacter ou à vous identifier
                            ("Données à Caractère Personnel"). Les données personnelles
                            nominatives peuvent comprendre, mais de manière non limitative:
  </p>

                        <ul>
                            <li>Adresse e-mail</li> <li>Prénom et nom de famille</li>{' '}
                            <li>Numéro de téléphone</li>{' '}
                            <li>Adresse, ville, province, état, code postal</li>{' '}
                            <li>Cookies et Données d'Utilisation</li>
                        </ul>

                        <h4>Données d'Utilisation</h4>

                        <p>
                            Nous pouvons également recueillir des informations relatives au mode
                            d'accès et d'utilisation du Service ("Données d'Utilisation"). Ces
                            Données d'Utilisation peuvent comprendre des informations telles que
                            l'adresse de protocole Internet (c.-à-d. l'adresse IP) de votre
                            ordinateur, le type de navigateur, la version du navigateur, les
                            pages de notre Service que vous consultez, la date et l'heure de
                            votre visite, le temps passé sur ces pages, les identifiants uniques
                            de dispositifs ainsi que d'autres données de diagnostic.
  </p>

                        <h4>Suivi et données de cookies</h4>
                        <p>
                            Nous avons recours à des cookies et à d'autres technologies de suivi
                            similaires pour effectuer un suivi des activités effectuées dans
                            notre Service, et nous conservons certaines informations.
  </p>
                        <p>
                            Les cookies sont des fichiers à faible volume de données pouvant
                            comporter un identifiant unique anonyme. Les cookies sont envoyés à
                            votre navigateur depuis un site web et sont stockés sur votre
                            dispositif. D'autres technologies de suivi telles que les pixels,
                            les balises et les scripts sont également utilisées pour recueillir
                            et suivre des informations et afin d'améliorer et d'analyser notre
                            Service.
  </p>
                        <p>
                            Vous pouvez demander à votre navigateur de refuser tous les cookies
                            ou de vous avertir lorsqu'un cookie est envoyé. Toutefois, si vous
                            n'acceptez pas les cookies, il se peut que vous ne puissiez pas
                            utiliser certains éléments de notre Service.
  </p>
                        <p>Exemples de cookies que nous utilisons :</p>
                        <ul>
                            <li>
                                <strong>Cookies de Session.</strong> Nous utilisons des Cookies de
      Session pour faire fonctionner notre Service.
    </li>
                            <li>
                                <strong>Cookies de Préférences.</strong> Nous utilisons des
      Cookies de Préférences pour mémoriser vos préférences et vos
      différents paramètres.
    </li>
                            <li>
                                <strong>Cookies de Sécurité.</strong> Nous utilisons des Cookies
      de Sécurité pour des raisons de sécurité.
    </li>
                        </ul>

                        <h2>Utilisation des données</h2>

                        <p>Brico Barrière utilise les données recueillies à des fins diverses:</p>
                        <ul>
                            <li>Pour fournir et assurer notre Service</li>
                            <li>
                                Pour vous faire part des changements apportés à notre Service
    </li>
                            <li>
                                Pour vous permettre d'utiliser les fonctions interactives de notre
                                Service quand vous le souhaitez
    </li>
                            <li>Pour assurer l'assistance client</li>
                            <li>
                                Pour recueillir des données précieuses ou d'analyses qui nous
                                permettront d'améliorer notre Service
    </li>
                            <li>Pour contrôler l'utilisation de notre Service</li>
                            <li>Pour détecter, prévenir et régler les problèmes techniques</li>
                        </ul>

                        <h2>Transfert des données</h2>
                        <p>
                            Les informations vous concernant, notamment vos Données à Caractère
                            Personnel, peuvent être transférées de votre région, province, pays,
                            ou autre division territoriale vers des ordinateurs – et stockées
                            sur ces derniers – situés à un endroit où la législation relative à
                            la protection des données diffère de celle du territoire où vous
                            résidez.
  </p>
                        <p>
                            Si vous résidez hors de/du Belgium et que vous choisissez de nous
                            communiquer des informations, sachez que nous transférons les
                            données, y compris les Données à Caractère Personnel, vers le/la
                            Belgium et que nous les y traitons.
  </p>
                        <p>
                            En acceptant la présente Politique de Confidentialité puis en
                            soumettant ces informations, vous consentez à ce transfert.
  </p>
                        <p>
                            Brico Barrière prendra toutes les mesures raisonnablement nécessaires pour
                            faire en sorte que vos données soient traitées de manière sécurisée
                            et conformément à la présente Politique de Confidentialité et vos
                            Données à Caractère Personnel ne seront transférées vers aucune
                            organisation ni aucun pays à moins que des contrôles adéquats ne
                            soient en place, notamment en ce qui concerne la sécurité de vos
                            données et d'autres données personnelles.
  </p>

                        <h2>Communication de données</h2>

                        <h3>Exigences légales</h3>
                        <p>
                            Brico Barrière peut communiquer vos Données à Caractère Personnel si elle
                            estime de bonne foi que cela est nécessaire pour:
  </p>
                        <ul>
                            <li>S'acquitter d'une obligation légale</li>
                            <li>Protéger et défendre les droits ou les biens de Brico Barrière</li>
                            <li>
                                Prévenir d'éventuels actes répréhensibles ou enquêter sur de tels
      actes dans le cadre du Service{' '}
                            </li>
                            <li>
                                Assurer la sécurité personnelle des utilisateurs du Service ou du
                                public
    </li>
                            <li>Se protéger contre la responsabilité civile</li>
                        </ul>

                        <h2>Sécurité des données</h2>
                        <p>
                            La sécurité de vos données nous tient à cœur. Toutefois, n'oubliez
                            pas qu'aucune méthode de transmission de données par Internet ou
                            méthode de stockage électronique n'est sûre à 100 %. Bien que nous
                            nous efforcions d'utiliser des méthodes commercialement acceptables
                            pour protéger vos Données à Caractère Personnel, nous ne pouvons pas
                            leur garantir une sécurité absolue.
  </p>

                        <h2>Prestataires de services</h2>
                        <p>
                            Nous pouvons faire appel à des sociétés tierces et à des tierces
                            personnes pour faciliter la prestation de notre Service
                            ("Prestataires de Services"), assurer le Service en notre nom,
                            assurer des services liés au Service ou nous aider à analyser la
                            façon dont notre Service est utilisé.
  </p>
                        <p>
                            Ces tiers n'ont accès à vos Données à Caractère Personnel que pour
                            effectuer ces tâches en notre nom et il leur est interdit de les
    communiquer ou de les utiliser à quelle qu'autre fin.{' '}
                        </p>

                        <h3>Analyses</h3>
                        <p>
                            Nous pouvons faire appel à des Prestataires de Services tiers pour
                            surveiller et analyser l'utilisation de notre Service.
  </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Clicky</strong>
                                </p>
                                <p>
                                    Clicky est un service d'analyse web. Vous pouvez lire la
        politique de confidentialité ici:{' '}
                                    <a href="https://clicky.com/terms">https://clicky.com/terms</a>
                                </p>
                            </li>
                        </ul>

                        <h2>Liens pointant vers d'autres sites</h2>
                        <p>
                            Il se peut que notre Service contienne des liens pointant vers
                            d'autres sites que nous n'exploitons pas. Si vous cliquez sur un
                            lien de tiers, vous serez redirigé vers le site de ce tiers. Nous
                            vous recommandons vivement d'examiner la politique de
                            confidentialité de chacun des sites que vous consultez.
  </p>
                        <p>
                            Nous n'avons aucun contrôle sur le contenu, les politiques ou
                            pratiques de confidentialité des sites ou services de tiers et
                            déclinons toute responsabilité en ce qui les concerne.
  </p>

                        <h2>Vie privée des enfants</h2>
                        <p>
                            Notre Service ne s'adresse pas aux personnes de moins de 18 ans
                            ("Enfants").
  </p>
                        <p>
                            Nous ne recueillons pas sciemment de données personnelles
                            nominatives auprès de personnes de moins de 18 ans. Si vous êtes un
                            parent ou un tuteur et que vous savez que votre Enfant nous a
                            communiqué des Données à Caractère Personnel, veuillez nous
                            contacter. Si nous apprenons que nous avons recueilli des Données à
                            Caractère Personnel auprès d'enfants sans vérifier s'il y a
                            consentement parental, nous faisons le nécessaire pour supprimer ces
                            informations de nos serveurs.
  </p>

                        <h2>Modifications de la présente Politique de Confidentialité</h2>
                        <p>
                            Nous nous réservons le droit d'actualiser notre Politique de
                            Confidentialité de temps à autre. Nous vous informerons de toute
                            modification en publiant la nouvelle Politique de Confidentialité
                            sur cette page.
  </p>
                        <p>
                            Avant que la modification ne prenne effet, nous vous en informerons
                            par e-mail et/ ou en plaçant un avis bien en vue dans notre Service
                            et nous actualiserons la "date de prise d'effet" qui figure en haut
                            de la présente Politique de Confidentialité.
  </p>
                        <p>
                            Nous vous conseillons de consulter la présente Politique de
                            Confidentialité périodiquement pour prendre connaissance de toute
                            modification. Les modifications apportées à la présente Politique de
                            Confidentialité prennent effet lorsqu'elles sont publiées sur cette
                            page.
  </p>

                        <h2>Nous contacter</h2>
                        <p>
                            Pour toute question relative à la présente Politique de
                            Confidentialité, veuillez nous contacter:
  </p>
                        <ul>
                            <li>Par courrier électronique: contact@Brico Barrière.be</li>
                            <li>En consultant cette page sur notre site web: Brico Barrière.be</li>
                        </ul>
                    </section>
                </article>
            </Layout >
        </>
    )

}

export default IndexPage

